import React, { useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { USER } from '../../constants/AppConstants';
import { httpClient } from '../../constants/Api';
import { toast } from "react-toastify";

function CkEditor({ values, setValues, setMessage, type, inputFields }) {
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [matchedUsers, setMatchedUsers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userNames, setUserNames] = useState("");
  const editorRef = useRef(null);

  const customDecode = (html) => {
    const decodedHtml = html.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    return decodedHtml;
  };

  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

const getUserDetails = async (matchedUsers) => {
  try {
    const userPromises = matchedUsers.map(userId =>
      httpClient.get(USER.GET_BY_ID.replace("{id}", userId))
    );

    const responses = await Promise.all(userPromises);
    const userData = responses.map(res => ({
      combined: `${res.data.user.name} (${res.data.user.emp_id})`, // Combine emp_id and name
      originalUser: res.data.user // Keep the original user object if needed
    }));
    setUserNames(userData);
    setMatchedUsers(userData);
    setShowDropdown(true);
  } catch (err) {
    // Handle errors for the batch request
    if (err.response) {
      toast.error(err.response.data.message);
    } else {
      toast.error("Something went wrong");
    }
  }
};

  const updateLinkPreview = async (editor) => {
    const data = editor.getData();
    const plainText = stripHtmlTags(data);
    
    
    const atIndex = plainText.lastIndexOf('@');
    if (atIndex !== -1) {
      const query = plainText.slice(atIndex + 1).toLowerCase();
      const combinedUsers = inputFields;
      if (combinedUsers && combinedUsers.length > 0) {
        getUserDetails(combinedUsers);
        setShowDropdown(true);
        setHighlightedIndex(0); 
      } else {
        setShowDropdown(false);
      }
    } else {
      setShowDropdown(false);
    }
    const containsLink = /https?:\/\/\S+/i.test(plainText);
    
    if (containsLink) {
      try {
        // Extract the link from the text
        const linkMatch = plainText.match(/(https?:\/\/\S+)/i);
        const link = linkMatch ? linkMatch[1] : '';
        
        // Create link preview only for the detected link
        const title = 'Review Link';
        const previewHTML = `
          ${plainText.substring(0, linkMatch.index)}
          <a href="${link}" rel="noopener noreferrer" style="text-decoration: none;" >
            ${title}
          </a>
          ${plainText.substring(linkMatch.index + link.length)}`;
        
        // Insert the content into the editor
        editor.setData(previewHTML);
        
        if (type === 'edit') {
          setMessage(previewHTML);
        }
        
        const newObj = { ...values, message: previewHTML };
        setValues(newObj);
      } catch (error) {
        console.error('Error fetching link data:', error);
      }
    } else {
      // No link detected, keep the original text
      if (type === 'edit') {
        setMessage(data);
      }
      const newObj = { ...values, message: data };
      setValues(newObj);
    }
    editor.editing.view.document.on('click', () => {
      setShowDropdown(false);
    });
  };  

 
  const handleUserSelect = (user) => {
    const newSelectedUsers = [user];
    setSelectedUsers(newSelectedUsers);
    updateEditorContent(newSelectedUsers);
};


const updateEditorContent = (users) => {
  if (!editorRef.current) return;

  const editor = editorRef.current;
  const data = editor.getData();
  const atIndex = data.lastIndexOf('@');
  const userText = users.map(user => user.combined).join(', ');
  const newText = data.substring(0, atIndex) + userText + ' ';
  editor.setData(newText);
  setShowDropdown(false);
  setMatchedUsers([]);
};


  return (
    <div style={{ position: 'relative' }}>
    <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'undo', 'redo', 'bulletedList', 'numberedList', 'blockQuote', 'indent', 'outdent'],
        }}
        data={customDecode(values.message)}
        onReady={(editor) => {
          editorRef.current = editor;
        }}
        onChange={(event, editor) => {
          updateLinkPreview(editor);
        }}
      />
     {showDropdown && (
        <div style={{ position: 'absolute', top: '50px', left: '10px', width: '310px', border: '1px solid #ccc', background: '#fff', zIndex: "10", borderRadius:"5px", fontSize: "14px", boxShadow: "0px 0px 5px #e3e1e1", }} className="message-dropdown">
          {matchedUsers.map((user, index) => (
            <div
              key={user}
              onClick={() => handleUserSelect(user)}
              style={{
                cursor: 'pointer',
                padding: '10px 10px',
                backgroundColor: highlightedIndex === index ? '#e0e0e0' : 'transparent',
              }}
            >
            {user.combined}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CkEditor;