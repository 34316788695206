export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAIL = "SIGNIN_FAIL";

//--------------------------------------------------------------
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const SIGNOUT_USER_FAIL = "SIGNOUT_USER_FAIL";

//------------------------------------------------
export const FETCH_TIMING = "FETCH_TIMING";
export const FETCH_TIMING_SUCCESS = "FETCH_TIMING_SUCCESS";
export const FETCH_TIMING_FAIL = "FETCH_TIMING_FAIL";
//---------------------------------------------------------------
export const CHECK_IN = "CHECK_IN";
export const CHECK_IN_SUCCESS = "CHECK_IN_SUCCESS";
export const CHECK_IN_FAIL = "CHECK_IN_FAIL";

//--------------------------------------------------
export const CHECK_OUT = "CHECK_OUT";
export const CHECK_OUT_SUCCESS = "CHECK_OUT_SUCCESS";
export const CHECK_OUT_FAIL = "CHECK_OUT_FAIL";

//------------------------------------------------------
export const BREAK_START = "BREAK_START";
export const BREAK_START_SUCCESS = "BREAK_START_SUCCESS";
export const BREAK_START_FAIL = "BREAK_START_FAIL";

//-----------------------------------------------------------
export const BREAK_END = "BREAK_END";
export const BREAK_END_SUCCESS = "BREAK_END_SUCCESS";
export const BREAK_END_FAIL = "BREAK_END_FAIL";

//-------------------------------------------------------------
export const FETCH_ROUTE = "FETCH_ROUTE";

//-------------------------------------------------------------
export const FETCH_CURRENT_DATE = "FETCH_CURRENT_DATE";
export const FETCH_CURRENT_DATE_SUCCESS = "FETCH_CURRENT_DATE_SUCCESS";
export const FETCH_CURRENT_DATE_FAIL = "FETCH_CURRENT_DATE_FAIL";
