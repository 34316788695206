import React from "react";

const ForbiddenPage = () => {
  return (
    <div>
      <p>You don't have permission to access this page.</p>
    </div>
  );
};

export default ForbiddenPage;